import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import QuoteIcon from '../../assets/icons/QuoteIcon';
import { isMessageDefinition } from '../../utils/message';
import styles from './QuotationSection.module.scss';

function MiniQuotationSection(props) {
  const { description, intl } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  return (
    <div className={styles.miniQuotationSectionContainer}>
      <div>
        <QuoteIcon fill="#172849" />
      </div>
      <div className={styles.miniQuotationSectionDescription}>
        {renderDescription()}
        <QuoteIcon className={styles.quoteIconRight} />
      </div>
    </div>
  );
}

MiniQuotationSection.propTypes = {
  description: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

MiniQuotationSection.defaultProps = {
  description: null
};

export default injectIntl(MiniQuotationSection);
