import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styles from './AprilInNumbers.module.scss';

const messages = defineMessages({
  subtitle1: {
    id: 'aprilInNumbers.subtitle1',
    defaultMessage: 'New Merchants Signed Up: '
  },
  description: {
    id: 'aprilInNumbers.description',
    defaultMessage: '4,723 (Total: 40,193 still 99% organic)'
  },
  subtitle2: {
    id: 'aprilInNumbers.subtitle2',
    defaultMessage: 'Users Visited our Shopify App Page: '
  },
  description2: {
    id: 'aprilInNumbers.description2',
    defaultMessage: '14,010 (Visitor to free trial conversion rate: 33%)'
  },
  subtitle3: {
    id: 'aprilInNumbers.subtitle3',
    defaultMessage: 'Monthly Recurring Revenue (MRR): '
  },
  description3: {
    id: 'aprilInNumbers.description3',
    defaultMessage: '$187,621'
  },
  subtitle4: {
    id: 'aprilInNumbers.subtitle4',
    defaultMessage: 'Total Paying Customers: '
  },
  description4: {
    id: 'aprilInNumbers.description4',
    defaultMessage: '3,840 (+236 compared to April)'
  },
  subtitle5: {
    id: 'aprilInNumbers.subtitle5',
    defaultMessage: 'Subscription Cancellations: '
  },
  description5: {
    id: 'aprilInNumbers.description5',
    defaultMessage: '515 (User churn rate: 13%)'
  },
  description6: {
    id: 'aprilInNumbers.description6',
    defaultMessage: 'Crossed 3,200 Shopify reviews – gained 400 in April'
  },
  subtitle6: {
    id: 'aprilInNumbers.subtitle6',
    defaultMessage: 'New Support Conversations: '
  },
  description7: {
    id: 'aprilInNumbers.description7',
    defaultMessage: '1,946'
  },
  subtitle7: {
    id: 'aprilInNumbers.subtitle7',
    defaultMessage: 'All Messenger Subscriptions: '
  },
  description8: {
    id: 'aprilInNumbers.description8',
    defaultMessage: '31,000,000 (+5m in April)'
  },
  subtitle8: {
    id: 'aprilInNumbers.subtitle8',
    defaultMessage: 'All Messenger Messages Sent: '
  },
  description9: {
    id: 'aprilInNumbers.description9',
    defaultMessage: '73,000,000 (+9m in April)'
  },
  subtitle9: {
    id: 'aprilInNumbers.subtitle9',
    defaultMessage: 'Full-time Team Size: 20 (+2 in April: '
  },
  description10: {
    id: 'aprilInNumbers.description10',
    defaultMessage: 'we’re hiring for several positions)'
  },
  description11: {
    id: 'aprilInNumbers.description11',
    defaultMessage:
      'Recart got a signification investment from Oktagon Ventures in May, 2019.'
  }
});

function AprilInNumbers() {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle2} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description2} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle3} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description3} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle4} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description4} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle5} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description5} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.description}>
          <FormattedMessage {...messages.description6} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle6} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description7} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle7} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description8} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle8} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description9} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.subtitle9} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.description10} />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <FormattedMessage {...messages.description11} />
        </div>
      </div>
    </div>
  );
}

export default AprilInNumbers;
