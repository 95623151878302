import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import App from '../../App';
import Hero from '../../components/Hero';
import ContactSection from '../../components/ContactSection';
import TechInfo from '../../components/TechInfo';
import MiniQuotationSection from '../../components/QuotationSection/MiniQuotationSection';
import AprilInNumbers from '../../components/AprilInNumbers';
import Container from '../../components/Container';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import RecartPageHeroImage from '../../gatsbyImages/RecartPageHeroImage';
import RecartMockupImage from '../../gatsbyImages/RecartMockupImage';
import RecartMockup2Image from '../../gatsbyImages/RecartMockup2Image';
import RecartMockup3Image from '../../gatsbyImages/RecartMockup3Image';
import styles from './projectPages.module.scss';

const messages = defineMessages({
  title: {
    id: 'recart.title',
    defaultMessage: 'Recart'
  },
  description: {
    id: 'recart.description',
    defaultMessage:
      'E-commerce messenger marketing plugin {br} Team extension: Ongoing {br}'
  },
  contactSectionTitle: {
    id: 'recart.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'recart.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  techUsed: {
    id: 'recart.techUsed',
    defaultMessage: 'Services: '
  },
  consulting: {
    id: 'recart.consulting',
    defaultMessage: 'Tech Consulting'
  },
  elimination: {
    id: 'recart.elimination',
    defaultMessage: 'Tech Debt Elimination'
  },
  development: {
    id: 'recart.development',
    defaultMessage: 'Feature Development'
  },
  firstSectionTitle: {
    id: 'recart.firstSectionTitle',
    defaultMessage:
      'StreamBright takes Recart’s high-growth app from MVP to high-functioning product'
  },
  firstSectionDescription1: {
    id: 'recart.firstSectionDescription1',
    defaultMessage: `Sell more with Messenger marketing The #1 headache in ecommerce is cart abandonment. 78% of all carts are never purchased. Recart's Abandoned Cart Messenger Reminders drive back the almost-buyers and convert them to recurring customers.`
  },
  firstSectionDescription2: {
    id: 'recart.firstSectionDescription2',
    defaultMessage: `Recart brings you what works best in Messenger marketing. You can enable their pre-built subscription tools and campaign templates with a single click and start generating extra revenue in minutes. {br} Engage and retain your customers through promotional Messenger campaigns such as newsletters, product announcements, sales alerts, re-engagement campaigns, and more.`
  },
  secondSectionTitle: {
    id: 'recart.secondSectionTitle',
    defaultMessage: 'Some of their features'
  },
  secondSectionDescription1Subtitle: {
    id: 'recart.secondSectionDescription1Subtitle',
    defaultMessage: `Shipping and delivery notificaitons`
  },
  secondSectionDescription1: {
    id: 'recart.secondSectionDescription1',
    defaultMessage: `Notify your customers when their package has left your warehouse, let them know when they can expect their shipment and drop them a lince once it has arrived. It's also an excellent opportunity to remind them about discount codes and generate more repeat pruchases.`
  },
  secondSectionDescription2Subtitle: {
    id: 'recart.secondSectionDescription2Subtitle',
    defaultMessage: `Sticky Discount Box`
  },
  secondSectionDescription2: {
    id: 'recart.secondSectionDescription2',
    defaultMessage: `Being an incentive based solution, Sticky Discount Box is a great way to get people to subscribe to your Messenger list\twith a visitor-to-customer conversation rate of 5-9%.`
  },
  secondSectionDescription3Subtitle: {
    id: 'recart.secondSectionDescription3Subtitle',
    defaultMessage: `Build your Messenger list with your favorite popups`
  },
  secondSectionDescription3: {
    id: 'recart.secondSectionDescription3',
    defaultMessage: `Like email: you need to ask for permission to message customers on Facebook Messenger. Unlike email: the subscription is a frictionless, one-click experience. No need for typing in emails or phone numbers. Just a single click.Recart Messenger subscription is now available in your favorite popup software.`
  },
  secondSectionDescription4Subtitle: {
    id: 'recart.secondSectionDescription4Subtitle',
    defaultMessage: `Order Page Subscription`
  },
  secondSectionDescription4: {
    id: 'recart.secondSectionDescription4',
    defaultMessage: `A tool designed specifically for the last steps of the user journey to capture those visitors who still have not subscribed earlier. Great for Order Confirmation and Photo Review requests.`
  },
  secondSectionDescription5: {
    id: 'recart.secondSectionDescription5',
    defaultMessage: `As you can see there are many features and tools that bring huge uplift to ecommerce stores using Recart's solutions. More than 80,000 ecommerce merchants have already installed Recart. They're one of the most popular and trusted ecommerce apps in the world. 5,000+ positive reviews on the Shopify App Store can speak for themselves.`
  },
  secondSectionDescription6: {
    id: 'recart.secondSectionDescription6',
    defaultMessage: `But how did they manage the rapid growth? Was there always enough time to find the best and most practical solution: Were they able to provide enough room for new features to build and maintain them while scaling up devops and engineering operations rapidly?`
  },
  secondSectionDescription7: {
    id: 'recart.secondSectionDescription7',
    defaultMessage: `Well, as you can see from their ratings, they did a fantastic job. On the other hand, let's be honest, - what customers don't see is the cards piling up in backlog or the features never rolled out, or the performance issues that are taking precious time away from being able to focus on these features and customer needs. To resolve this situation, they've decided to hire someone who can help them get rid of those cards.`
  },
  secondSectionDescription8: {
    id: 'recart.secondSectionDescription8',
    defaultMessage: `While getting familiar with their tech stack and software solution, we were able to develop a perspective that enabled the rationalization of the code of Reacert's discount widget. This resulted in higher scalability, easier maintenance, and also it will be way easier for anyone to catch up in the development of the widget module.`
  },
  secondSectionDescription9: {
    id: 'recart.secondSectionDescription9',
    defaultMessage: `As part of our pilot we were also adding a new module - a conversation starter - to the control panel page. This helps their customers to start better conversation with their clients.`
  },
  quotationDescription1: {
    id: 'recart.quotationDescription1',
    defaultMessage: `The cooperation was so fluent, we liked each other's attitude, professionalism and experience so much there is no doubt we are going to provide a next level service together in the future.`
  },
  miniQuotationDescription1: {
    id: 'recart.miniQuotationDescription1',
    defaultMessage:
      'While in the background we are continuously working on improving their digital product and to provide even more satisfying customer and developer experience, Recart is now finally able to focus on their bright future.'
  },
  thirdSectionTitle: {
    id: 'recart.thirdSectionTitle',
    defaultMessage: '2019 April in numbers'
  }
});

function Recart() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Case Study: SaaS Case Study</title>
        <meta
          name="description"
          content="How StreamBright took a leading video editor from popular hit to standout success."
        />
      </Helmet>
      <Hero
        icon={<BlueDotsIcon />}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            <FormattedMessage
              {...messages.description}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.consulting} />
              <TechInfo title={messages.elimination} />
              <TechInfo title={messages.development} />
            </div>
          </>
        }
        backgroundImage={RecartPageHeroImage}
        image={RecartMockupImage}
      />
      <Container>
        <div className={styles.title}>
          <FormattedMessage {...messages.firstSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage
            {...messages.firstSectionDescription2}
            values={{ br: <br /> }}
          />
        </div>
        <div className={styles.title}>
          <FormattedMessage {...messages.secondSectionTitle} />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.secondSectionDescription1Subtitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription1} />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.secondSectionDescription2Subtitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription2} />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.secondSectionDescription3Subtitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription3} />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage {...messages.secondSectionDescription4Subtitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription4} />
        </div>
        <div className={cn(styles.imageContainer, styles.mockupImageContainer)}>
          <RecartMockup2Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div className={styles.rightBottomGreenIcon}>
            <GreenSquareIcon />
          </div>
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription5} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription6} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription7} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription8} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription9} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription1}
        />
        <div className={cn(styles.imageContainer, styles.recartImageContainer)}>
          <RecartMockup3Image
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div className={styles.leftBottomBlueIcon}>
            <BlueDotsIcon />
          </div>
        </div>
        <div className={styles.title}>
          <FormattedMessage {...messages.thirdSectionTitle} />
        </div>
        <AprilInNumbers />
      </Container>
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default Recart;
